<template>
  <div class="time-slot" :class="{'table-danger rounded': $v.$invalid}">

    <div class="row">
      <div class="col-12 col-sm-4 mb-2 mb-sm-0">
        <div class="vertical-center flex-gap-2">
          <CSwitch
            labelOn="OPN"
            labelOff="CLS"
            color="success"
            size="lg"
            variant="opposite"
            :checked.sync="isWorking"
          />
          <span class="label">{{ day }}</span>
        </div>
      </div>

      <div class="col-5 col-sm-3 px-sm-0">
        <CSelectHour :value.sync="$v.form.open1.$model" v-if="isWorking" />
        <div v-else class="d-none d-sm-block form-group disable-item">
          <span class="label form-control d-flex align-items-center flex-gap-1"><span class="dot bg-danger"></span> Closed all day</span>
        </div>
      </div>

      <div class="col-5 col-sm-3">
        <!-- <CSelectHour v-if="isWorking" :value.sync="$v.form.close1.$model" :min="form.open1" /> -->
        <CSelectHour v-if="isWorking" :value.sync="$v.form.close1.$model" />
      </div>

      <div class="col-2 px-0">
        <template v-if="isWorking && form.close1 != endOfDayTime">
          <CButton
            v-if="!form.open2"
            color="success"
            size="sm"
            variant="ghost"
            shape="pill"
            @click="form.open2 = form.close1; form.close2 = endOfDayTime"
          >
            <CIcon name="cil-plus" size="xl" />
          </CButton>
          <CButton
            v-else-if="!form.open3"
            color="danger"
            size="sm"
            variant="ghost"
            shape="pill"
            @click="form.open2 = form.close2 = null"
          >
            <div class="button-content"><CIcon name="cil-x" size="xl" /><span class="d-none d-md-inline"> Slot 2</span></div>
          </CButton>
        </template>
      </div>
    </div>

    <div class="row" v-if="form.open2">
      <div class="col-12 col-sm-4">
      </div>

      <div class="col-5 col-sm-3 px-sm-0">
        <CSelectHour :value.sync="$v.form.open2.$model" :min="form.close1" />
      </div>

      <div class="col-5 col-sm-3">
        <!-- <CSelectHour :value.sync="$v.form.close2.$model" :min="form.open2" /> -->
        <CSelectHour :value.sync="$v.form.close2.$model" />
      </div>

      <div class="col-2 px-0">
        <template v-if="form.close2 != endOfDayTime">
          <CButton
            v-if="!form.open3"
            color="success"
            size="sm"
            variant="ghost"
            shape="pill"
            @click="form.open3 = form.close2; form.close3 = endOfDayTime"
          >
            <CIcon name="cil-plus" size="xl" />
          </CButton>
          <CButton
            v-else
            color="danger"
            size="sm"
            variant="ghost"
            shape="pill"
            @click="form.open3 = form.close3 = null"
          >
            <div class="button-content"><CIcon name="cil-x" size="xl" /><span class="d-none d-md-inline"> Slot 3</span></div>
          </CButton>
        </template>
      </div>
    </div>

    <div class="row" v-if="form.open3">
      <div class="col-12 col-sm-4">
      </div>

      <div class="col-5 col-sm-3 px-sm-0">
        <CSelectHour :value.sync="$v.form.open3.$model" :min="form.close2" />
      </div>

      <div class="col-5 col-sm-3">
        <!-- <CSelectHour :value.sync="$v.form.close3.$model" :min="form.open3" /> -->
        <CSelectHour :value.sync="$v.form.close3.$model" />
      </div>

      <div class="col-2"></div>
    </div>

  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import CSelectHour from "@/components/CSelectHour";

export default {
  name: "restaurant-times",
  components: {
    CSelectHour
  },
  props: {
    day: { default: null, type: String },
    time: { default: null, type: String },
  },
  data() {
    return {
      form: {
        open1: null,
        close1: null,
        open2: null,
        close2: null,
        open3: null,
        close3: null,
      },
      endOfDayTime: '23:59'
    };
  },

  mixins: [validationMixin],
  validations: {
    form: {
      open1: {},
      open2: {},
      open3: {},

      close1: {
        // required: function () {
        //   return this.form.open1
        //     ? typeof this.form.close1 !== "undefined" &&
        //         this.form.close1 !== null
        //     : true;
        // },
        // minValue: function () {
        //   const close = this.moment(this.form.close1, 'HH:mm');
        //   const open = this.moment(this.form.open1, 'HH:mm');
        //   return this.form.open1 ? (close.isAfter(open)) : true;
        // },
      },
      close2: {
        // required: function () {
        //   return this.form.open2
        //     ? typeof this.form.close2 !== "undefined" &&
        //         this.form.close2 !== null
        //     : true;
        // },
        // minValue: function () {
        //   const close = this.moment(this.form.close2, 'HH:mm');
        //   const open = this.moment(this.form.open2, 'HH:mm');
        //   return this.form.open2 ? (close.isAfter(open)) : true;
        // },
      },
      close3: {
        // required: function () {
        //   return this.form.open3
        //     ? typeof this.form.close3 !== "undefined" &&
        //         this.form.close3 !== null
        //     : true;
        // },
        // minValue: function () {
        //   const close = this.moment(this.form.close3, 'HH:mm');
        //   const open = this.moment(this.form.open3, 'HH:mm');
        //   return this.form.open3 ? (close.isAfter(open)) : true;
        // },
      },
    },
  },

  watch: {
    //props
    time: [{
      handler: 'splitTime'
    }],

    //data
    form: {
      deep: true,
      handler() {
        this.$emit("update:time", this.str_times);
        this.$emit("isValid", !this.$v.$invalid);
      },
    },
  },

  computed: {
    str_times() {
      var array_times = Object.values(this.form).filter((n) => n); // remove null, empty elements
      var str = `${array_times.slice(0, 2).join("-")},${array_times
        .slice(2, 4)
        .join("-")},${array_times.slice(4).join("-")}`;

      // console.log(str.replace(/,+$/, ''));
      // $ marks the end of a string
      // ,+$ means: all , characters at the end of a string
      return str.replace(/,+$/, "");
    },

    isWorking: {
      get: function () { return Object.values(this.form).some(t => t !== null  && t !== "") },
      set: function (val) {
        const defaultForm = {
          open1: null,
          close1: null,
          open2: null,
          close2: null,
          open3: null,
          close3: null,
        };

        this.form = {
          ...defaultForm,
          ...val ? { open1: '00:00', close1: this.endOfDayTime } : {},
        };
      }
    },
  },

  async mounted() {
    this.splitTime();
  },

  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.form[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },

    splitTime() {
      var times = [];
      this.time?.split(",").forEach((part) => {
        part.split("-").forEach((t) => {
          times.push(t);
        });
      });

      this.form = {
        open1: times[0] ?? null,
        close1: times[1] ?? null,
        open2: times[2] ?? null,
        close2: times[3] ?? null,
        open3: times[4] ?? null,
        close3: times[5] ?? null,
      };
    },
  },
};
</script>

<style scoped>
.time-slot {
  border-top: 1px solid #d8dbe0 !important;
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  margin-bottom: 0.25rem;
}

.time-slot .form-group {
  margin-bottom: 0.25rem;
}

.time-slot .disable-item .form-control {
  border: none !important;
}

.time-slot .button-content {
  display: flex;
  align-items: center;
}

span.label {
  font-size: 1.125rem;
  font-weight: 500;
}
.vertical-center {
  display: flex;
  align-items: center;
}
</style>

<style>
.time-slot select.form-control {
  font-size: 1.125rem;
  color: #9b0a0a;
  font-weight: 500;
}

.was-validated .form-control:valid, .form-control.is-valid {
  padding-right: 0.75rem !important;
  border-color: #e4e7ea !important;
  background-image: none !important;
}
.was-validated .form-control:invalid, .form-control.is-invalid {
  padding-right: 0.75rem !important;
  background-position: right calc(0.025rem) center !important;
}
</style>
