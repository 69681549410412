<template>
  <CSelect
    :label="label"
    :placeholder="placeholder"
    :value.sync="valueSync"
    :options="options"
  />
</template>

<script>
export default {
  name: "CSelectHour",
  props: {
    label: String,
    placeholder: String,
    value: String,
    min: String,
    excludeRange: {
      type: Object,
      default: () => null,
      // default: () => ({ min: "00:00", max: "08:00" }),
    },
  },
  computed: {
    options() {
      var times = [];
      for (let hours = 0; hours < 24; hours++) {
        for (let minutes = 0; minutes < 60; minutes += 15) {
          const value = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
          times.push({
            value,
            disabled: this.min && this.isDisabled(value),
            visible: this.excludeRange ? this.isVisible(value) : true
          });
        }
      }
      times.push({ value: `23:59`, disabled: false, visible: true });

      return times.filter(time => time.visible && !time.disabled);
    },
    valueSync: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("update:value", newValue);
      },
    },
  },
  methods: {
    isDisabled(timeValue) {
      if (!this.min) {
        return false;
      }
      const [minH, minM] = this.min.split(':').map(Number);
      const [selH, selM] = timeValue.split(':').map(Number);
      return selH < minH || (selH === minH && selM < minM);
    },

    isVisible(timeValue) {
      if (!this.excludeRange) {
        return true;
      }

      const min = this.moment(this.excludeRange.min, 'HH:mm');
      const max = this.moment(this.excludeRange.max, 'HH:mm');
      const time = this.moment(timeValue, 'HH:mm');

      return !(time.isAfter(min) && time.isBefore(max));
      // return !(time.isSameOrAfter(min) && time.isSameOrBefore(max));
    },
  },
};
</script>
